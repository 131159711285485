'use client';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import { cn } from '@/lib/utils';
import { forwardRef } from 'react';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

interface AdvanceComponentProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  withoutPortal?: boolean;
  closeByAction?: boolean;
}

const PopoverContent = forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, AdvanceComponentProps>(
  ({ className, align = 'center', sideOffset = 4, withoutPortal = false, closeByAction = false, ...props }, ref) => {
    const content = (
      <PopoverPrimitive.Content
      // @INFO: Fix issue: "Close the modal after clicking on the Google Places popover."
      onInteractOutside={(e) => {
        if (closeByAction) {
          e.preventDefault();
        }
        
        const hasPacContainer = e.composedPath().some((el: EventTarget) => {
          if ("classList" in el) {
            return Array.from((el as Element).classList).includes("pac-container")
          }
          return false
        })
      
        if (hasPacContainer) {
          e.preventDefault()
        }
      }}
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          'static z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          className
        )}
        {...props}
      />
    );
    return withoutPortal ? content : <PopoverPrimitive.Portal>{content}</PopoverPrimitive.Portal>;
  }
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };

export enum RoleTypes {
  'Super admin' = 'Super admin',
  'Admin' = 'Admin',
  'Guardian' = 'Guardian',
  'Coordinator' = 'Coordinator',
  'Assistant' = 'Assistant',
  'Assistant Limited' = 'Assistant Limited',
  'Therapist' = 'Therapist',
  'Therapist Limited' = 'Therapist Limited',
}

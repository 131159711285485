import { Colors } from "../enums";

export const STATES: string[] = [
  'On Hold',
  'Rejected',
  'Terminated',
  'Pending Approval',
  'Client Available',
  'Contacting Parents',
  'Therapist Assigned',
];

// export const STATES_COLORS_CLASSES = {
//   'On Hold': Colors.blue,
//   'Rejected': Colors.red,
//   'Terminated': Colors.orange,
//   'Pending Approval': Colors.purple,
//   'Client Available': Colors.green,
//   'Contacting Parents': Colors.pink,
//   'Therapist Assigned': Colors.cyan,
// };

export enum STATES_TYPES {
    ON_HOLD = 'On Hold',
    REJECTED = 'Rejected',
    TERMINATED = 'Terminated',
    PENDING_APPROVAL = 'Pending Approval',
    CLIENT_AVAILABLE = 'Client Available',
    CONTACTING_PARENTS = 'Contacting Parents',
    THERAPIST_ASSIGNED = 'Therapist Assigned',
  }

export enum Colors {
  purple = 'purple',
  green = 'green',
  blue = 'blue',
  orange = 'orange',
  amber = 'amber',
  yellow = 'yellow',
  'yellow-strong' = 'yellow-strong',
  indigo = 'indigo',
  slate = 'slate',
  'orange-coral' = 'orange-coral',
  'lime-olive' = 'lime-olive',
  'slate-astral' = 'slate-astral',
  'slate-strong' = 'slate-strong',
  'slate-light' = 'slate-light',
  'slate-green' = 'slate-green',
  zinc = 'zinc',
  'zinc-light' = 'zinc-light',
  lime = 'lime',
  stone = 'stone',
  teal = 'teal',
  neutral = 'neutral',
  red = 'red',
  'red-orange' = 'red-orange',
  'orange-light' = 'orange-light',
  'red-pink' = 'red-pink',
  pink = 'pink',
  rose = 'rose',
  cyan = 'cyan',
  gray = 'gray',
}